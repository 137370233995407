@use '@angular/material' as mat;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$agilicus-blue: #0057b8;
$agilicus-green: #57b800;
$agilicus-red: #b80057;

// http://mcg.mbitson.com (https://github.com/mbitson/mcg)
$mat-agilicus-blue: (
  50: #e0ebf6,
  100: #b3cdea,
  200: #80abdc,
  300: #4d89cd,
  400: #2670c3,
  500: #0057b8,
  600: #004fb1,
  700: #0046a8,
  800: #003ca0,
  900: #002c91,
  A100: #bdcbff,
  A200: #8aa3ff,
  A400: #577cff,
  A700: #3d68ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$mat-agilicus-red: (
  50: #f6e0eb,
  100: #eab3cd,
  200: #dc80ab,
  300: #cd4d89,
  400: #c32670,
  500: #b80057,
  600: #b1004f,
  700: #a80046,
  800: #a0003c,
  900: #91002c,
  A100: #ffbdcb,
  A200: #ff8aa3,
  A400: #ff577c,
  A700: #ff3d68,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$mat-agilicus-green: (
  50: #ebf6e0,
  100: #cdeab3,
  200: #abdc80,
  300: #89cd4d,
  400: #70c326,
  500: #57b800,
  600: #4fb100,
  700: #46a800,
  800: #3ca000,
  900: #2c9100,
  A100: #cbffbd,
  A200: #a3ff8a,
  A400: #7cff57,
  A700: #68ff3d,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$portal-primary: mat.m2-define-palette($mat-agilicus-blue, 500);
$portal-accent: mat.m2-define-palette($mat-agilicus-green, A200, A100, A400);

$portal-warn: mat.m2-define-palette($mat-agilicus-red);

// Create the theme object (a Sass map containing all of the palettes).
$portal-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $portal-primary,
      accent: $portal-accent,
      warn: $portal-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

@include mat.core-theme($portal-theme);
@include mat.button-theme($portal-theme);

h1 {
  font-family: Canada1500, Roboto, 'Helvetica Neue', sans-serif;
}
h2 {
  font-family: Canada1500, Roboto, 'Helvetica Neue', sans-serif;
}
h3 {
  font-family: Canada1500, Roboto, 'Helvetica Neue', sans-serif;
}
header {
  font-family: Canada1500, Roboto, 'Helvetica Neue', sans-serif;
}
body {
  font-family: 'Proxima Nova', Roboto, 'Helvetica Neue', sans-serif;
}
