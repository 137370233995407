@font-face {
  font-family: 'Canada1500-Lt';
  src: url('Canada1500Lt-Italic.woff2') format('woff2'),
    url('Canada1500Lt-Italic.woff') format('woff'),
    url('Canada1500Lt-Italic.ttf') format('truetype'),
    url('Canada1500Lt-Italic.svg#Canada1500Lt-Italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Canada1500';
  src: url('Canada1500Rg-Italic.woff2') format('woff2'),
    url('Canada1500Rg-Italic.woff') format('woff'),
    url('Canada1500Rg-Italic.ttf') format('truetype'),
    url('Canada1500Rg-Italic.svg#Canada1500Rg-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Canada1500-Lt';
  src: url('Canada1500Lt-Regular.woff2') format('woff2'),
    url('Canada1500Lt-Regular.woff') format('woff'),
    url('Canada1500Lt-Regular.ttf') format('truetype'),
    url('Canada1500Lt-Regular.svg#Canada1500Lt-Regular') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Canada1500';
  src: url('Canada1500Rg-Bold.woff2') format('woff2'),
    url('Canada1500Rg-Bold.woff') format('woff'),
    url('Canada1500Rg-Bold.ttf') format('truetype'),
    url('Canada1500Rg-Bold.svg#Canada1500Rg-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Canada1500';
  src: url('Canada1500Rg-Regular.woff2') format('woff2'),
    url('Canada1500Rg-Regular.woff') format('woff'),
    url('Canada1500Rg-Regular.ttf') format('truetype'),
    url('Canada1500Rg-Regular.svg#Canada1500Rg-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Canada1500';
  src: url('Canada1500Rg-BoldItalic.woff2') format('woff2'),
    url('Canada1500Rg-BoldItalic.woff') format('woff'),
    url('Canada1500Rg-BoldItalic.ttf') format('truetype'),
    url('Canada1500Rg-BoldItalic.svg#Canada1500Rg-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}
