@use '@angular/material' as mat;
@import 'styles-variables';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'vertical-tabs-styles';

@import 'assets/fonts/canada1500/stylesheet.css';
@import 'assets/fonts/proxima-nova/stylesheet.css';
@import 'assets/fonts/roboto/stylesheet.css';
@import 'assets/fonts/material/stylesheet.css';

@import 'themes/default-theme.scss';

@import 'app/app.component.scss-theme';

@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

@include mat.core();

@mixin custom-components-theme($theme) {
  @include portal-app-component-theme($theme);
}

@include mat.all-component-themes($portal-theme);
@include custom-components-theme($portal-theme);

//https://github.com/angular/components/issues/4892
.mat-mdc-tooltip-trigger {
  touch-action: auto !important;
}

.policy-rule-conditions .table-controls-container .mat-mdc-form-field-infix {
  width: unset;
  .mat-mdc-select-value {
    max-width: fit-content;
  }
}

.mat-mdc-standard-chip {
  height: unset !important;
}

.audit-destinations {
  .mat-mdc-form-field-infix {
    width: 150px !important;
  }
}

.audit-subsystem-container,
.connector-table-container {
  .small-cell {
    .mat-mdc-form-field-infix {
      width: 100px !important;
    }
  }
}

.validation-stepper {
  margin-top: 20px;
  .progress-step:before {
    margin-left: 0 !important;
  }
  .progress-track {
    top: 7px !important;
    height: 2px !important;
    z-index: unset !important;
  }
  .step-label {
    color: #222526 !important;
  }
  .progress-track {
    left: 0 !important;
  }
  .progress-step.is-complete:before {
    font-weight: bold !important;
  }
}

.account-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  position: relative !important; /* Ensure the button acts as a container */
}

.account-button img {
  display: block !important; /* Ensure the image respects its container */
  width: 40px !important; /* Adjust based on your design */
  height: 40px !important; /* Keep a fixed size for consistency */
  border-radius: 50% !important;
  object-fit: cover !important; /* Ensures proper scaling of the image */
  z-index: 1 !important; /* Prevents the image from being overlapped by other elements */
}

.account-button .mat-mdc-button-persistent-ripple,
.account-button .mat-mdc-focus-indicator,
.account-button .mat-mdc-button-touch-target {
  display: none !important; /* Hides extra Angular Material elements that might affect layout */
}

.main-toolbar {
  .mdc-text-field--filled {
    background-color: unset !important;
  }

  .mat-mdc-select,
  .mat-mdc-select-arrow {
    color: white !important;
  }

  .mdc-text-field--filled .mdc-line-ripple::before {
    border-bottom-color: white !important;
  }
}

.mdc-list-item__content {
  padding: 0 16px;
}

.mdc-list-item__primary-text {
  display: flex;
}

.side-menu-option {
  // html variable
  padding-left: var(--left-pad) !important;
  .mdc-list-item__primary-text {
    font-weight: 450 !important;
    font-size: 17px !important;
  }
}

.sidenav-icon {
  margin-right: 10px;
}

.sidenav-nested-menu {
  .side-menu-option {
    height: 34px !important;
    .mdc-list-item__primary-text {
      font-weight: 400 !important;
      color: #464444 !important;
      font-size: 16px !important;
    }
  }

  .nested-level-2 .side-menu-option {
    height: 30px !important;
    .mdc-list-item__primary-text {
      color: #6b6b6c !important;
      font-size: 15px !important;
    }
  }
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  letter-spacing: normal !important;
}

.mat-mdc-form-field,
.mat-mdc-select {
  display: inline-block !important;
  position: relative;
  text-align: left;
  letter-spacing: normal !important;
  line-height: 1.125 !important;
  font-size: 14px !important;
}

.mdc-text-field--filled,
.mat-mdc-text-field-wrapper:hover,
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

td {
  // .mat-mdc-form-field-appearance-outline {
  .mdc-notched-outline {
    .mat-mdc-form-field-flex {
      padding: 0 0.75em 0 0.75em;
      margin-top: -0.25em;
    }
  }
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: calc(var(--mat-form-field-container-height) / 3) !important;
}
