@use '@angular/material' as mat;

$toolbar-breakpoint: 600px;

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$success-colors: mat.m2-define-palette(mat.$m2-green-palette, 400);
$warning-colors: mat.m2-define-palette(mat.$m2-amber-palette, 400);

$primaryColor: #0057b8;
$primaryColorLighter: #9ecdfc;
$primaryColorFaded: #e0ebf6;
$successColor: #a3ff8a;
$warnColor: #b80057;
$intermediateColor: #ff9800;
$disabledColor: rgba(0, 0, 0, 0.26);
$readOnlyColor: rgba(0, 0, 0, 0.432);
$disabledRowColor: rgb(235, 235, 228);
