@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Black.woff2') format('woff2'),
    url('ProximaNova-Black.woff') format('woff'),
    url('ProximaNova-Black.ttf') format('truetype'),
    url('ProximaNova-Black.svg#ProximaNova-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Extrabld.woff2') format('woff2'),
    url('ProximaNova-Extrabld.woff') format('woff'),
    url('ProximaNova-Extrabld.ttf') format('truetype'),
    url('ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Regular.woff2') format('woff2'),
    url('ProximaNova-Regular.woff') format('woff'),
    url('ProximaNova-Regular.ttf') format('truetype'),
    url('ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Bold.woff2') format('woff2'),
    url('ProximaNova-Bold.woff') format('woff'),
    url('ProximaNova-Bold.ttf') format('truetype'),
    url('ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNovaT-Thin.woff2') format('woff2'),
    url('ProximaNovaT-Thin.woff') format('woff'),
    url('ProximaNovaT-Thin.ttf') format('truetype'),
    url('ProximaNovaT-Thin.svg#ProximaNovaT-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}
