@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('Roboto-Light.woff2') format('woff2'),
    url('Roboto-Light.woff') format('woff'), url('Roboto-Light.ttf') format('truetype'),
    url('Roboto-Light.svg#Roboto-Light') format('svg')
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('Roboto-Regular.woff2') format('woff2'),
    url('Roboto-Regular.woff') format('woff'), url('Roboto-Regular.ttf') format('truetype'),
    url('Roboto-Regular.svg#Roboto-Regular') format('svg')
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('Roboto-Medium.woff2') format('woff2'),
    url('Roboto-Medium.woff') format('woff'), url('Roboto-Medium.ttf') format('truetype'),
    url('Roboto-Medium.svg#Roboto-Medium') format('svg')
}
