.verical-tabs {
  .mat-mdc-tab-labels {
    display: grid !important;
  }

  .mat-mdc-tab {
    text-align: left;
    justify-content: start;
    padding: 0;
    padding-right: 20px;
    border-bottom: 1px solid #ededed;
    .mdc-tab__content {
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }

  .mat-mdc-tab-header {
    width: 20%;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
    border-bottom: none;
  }

  .mat-mdc-tab-body-wrapper {
    width: 80%;
    padding-left: 20px;
  }

  .table-controls-container {
    width: 100% !important;
  }

  .table-container {
    width: 100% !important;
  }

  .descriptive-text-container {
    display: none !important;
  }

  /* Styles for the active tab label */
  .mat-mdc-tab.mdc-tab--active {
    border-right: 2px solid #0057b8;
    opacity: 1;
  }

  .mat-mdc-tab-header-pagination {
    display: none !important;
  }

  .mat-mdc-tab-group {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    border: solid 1px rgba(0, 0, 0, 0.12);
    border: solid 1px rgba(0, 0, 0, 0.12);
    padding: 20px;
    padding-right: 0;
  }

  .mat-mdc-tab-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  mat-ink-bar {
    display: none;
  }

  // Will override the style defaults, but only for elements
  // in the html linked to this file
  :host {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  :host ::ng-deep {
    .mat-mdc-tab-labels {
      flex-direction: column;
    }
    .mat-mdc-tab-body-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    mat-ink-bar {
      display: none;
    }
  }

  .mat-expansion-panel-header-description {
    flex-grow: 1;
  }
}
